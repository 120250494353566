<template>
	<div class="mt-6 ml-2" fluid>
    <v-row>
      <v-col cols="12">
        <div class="container" >

          <v-card class="transparent elevation-0">
            <v-toolbar
              color="#E91E63"
              dark
              dense
            >
              <v-text-field
                name="name"
                label="Buscar chat"
                v-model="folioProspecto"
                hide-details
                dense
                filled
                rounded
                single-line
                append-icon="mdi-magnify"
                @keyup.enter="buscarProspecto()"
              ></v-text-field>

              <v-spacer></v-spacer>

              <v-btn text @click="initialize()"><v-icon>mdi-refresh</v-icon></v-btn>
            </v-toolbar>
          </v-card>

          <div class="row" style="margin: 0px">
            <section class="chat">

              <!-- HEADER -->
              <!-- <div class="header-chat">
                <div class="name" v-if="alumno && alumno.tipoChat == 'chat'"> {{ alumno.notifyName }}</div>
                <div class="name" v-if="alumno && alumno.tipoChat == 'grupo'">GRUPO CHAT</div>
                <br/>
                <div class="name" v-if="alumno"> {{ alumno.froms }}</div>
              </div> -->

              <!-- MENSAJES -->
              <div 
              	class="messages-chat" 
                :style="`max-height: 610px; overflow: auto;`" 
                id="mensajesChat"
              >
              	<div id="scroll">
	                <span v-for="(mensaje, i) in filterMensajes" :key="i">

	                  <div class="message text-only">
	                    <div :class="`${ mensaje.fromMe == 0 ? 'noresponse' : 'response' }`">

	                      <p 
	                        class="text" 
	                        v-if="mensaje.type == 'chat'"
	                        style="white-space: pre-line"
	                      >
                          <span v-if="alumno && alumno.tipoChat == 'grupo'" class="subnombrechat">{{ mensaje.notifyName }}</span>
                          <br v-if="alumno && alumno.tipoChat == 'grupo'"/>
	                        {{ mensaje.body }}
	                      </p>

	                      <p 
	                        class="text" 
	                        v-if="mensaje.type == 'image'"
	                        style="white-space: pre-line"
	                        @click="verImagen( mensaje.fullFileName )"
	                        type="button"
	                      >
	                        <img :src="mensaje.url_servidor + '/whatsapp-imagenes/' + mensaje.fullFileName" alt="Red dot" width="300" />
	                      </p>

	                      <p 
	                        class="text" 
	                        v-if="mensaje.type == 'sticker'"
	                        style="white-space: pre-line"
	                      >
	                        <img :src="mensaje.url_servidor + '/whatsapp-imagenes/' + mensaje.fullFileName" alt="Red dot" width="150"/>
	                      </p>

	                      <p 
	                        class="text" 
	                        v-if="mensaje.type == 'document'"
	                        style="white-space: pre-line"
	                      >
	                        <v-chip
	                        	v-if="mensaje.mimetype == 'application/pdf'"
											      color="pink"
											      label
											      text-color="white"
											      @click="verPDF( mensaje.fullFileName )"
											    >
											      <v-icon left>
											        mdi-file-pdf-box
											      </v-icon>
											      {{ mensaje.mimetype }}
											    </v-chip>
	                      </p>

	                      <p 
	                        class="text pa-0 pt-2 px-2" 
	                        v-if="mensaje.type == 'ptt'"
	                        style="white-space: pre-line"
	                      >
	                        <audio controls>
													  <source :src="mensaje.url_servidor + '/whatsapp-imagenes/' + mensaje.fullFileName" type="audio/ogg" >
													</audio>
	                      </p>

	                      <p 
	                        class="text" 
	                        v-if="mensaje.type == 'video'"
	                        style="white-space: pre-line"
	                      >
	                        <video controls width="300" v-if="mensaje.isGif == 0">
	                        	<source :src="mensaje.url_servidor + '/whatsapp-imagenes/' + mensaje.fullFileName">
	                        </video>
	                        
	                        <video width="300" v-if="mensaje.isGif == 1" autoplay loop muted>
	                        	<source :src="mensaje.url_servidor + '/whatsapp-imagenes/' + mensaje.fullFileName">
	                        </video>
	                      </p>

	                      <p 
	                      	v-if="mensaje.fromMe == 1"
	                        :class="`${ mensaje.fromMe == 0 ? 'time' : 'response-time time'} black--text`"
	                      >{{ mensaje.vendedora }}	<br/></p>

	                      <p 
	                        :class="`${ mensaje.fromMe == 0 ? 'time' : 'response-time time'} black--text`"
	                      >{{ mensaje.fecha_creacion }}</p>

	                    </div>
	                  </div>

	                </span>
                  <v-text-field
                    label="Buscar en el chat"
                    filled
                    rounded
                    v-if="alumno" 
                    hide-details
                    single-line
                    dense
                    append-icon="mdi-magnify"
                    v-model="searchMensaje"
                  ></v-text-field>
              	</div>
              </div>
            </section>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="dialgImagen" max-width="600px" persistent :fullscreen="fullscreen">
      <v-card class="elevation-0">
        <v-card-text>
          <!-- Imagen -->
          <v-col cols="12">
            <v-card height="100%" class="elevation-0" align="center" outlined v-if="usuario">
              <v-img :src="usuario.url_servidor + '/whatsapp-imagenes/' + imagen" contain aspect-ratio="2" v-if="fullscreen">
              </v-img>

              <v-img :src="usuario.url_servidor + '/whatsapp-imagenes/' + imagen" contain aspect-ratio="2" max-width="450" v-else >
              </v-img>
            </v-card>
          </v-col>
        </v-card-text>
        <v-card-actions class="pt-0" >
          <v-spacer></v-spacer>
          <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
          <v-btn color="error" text large class="mr-2" @click="fullscreen = true" v-if="!fullscreen">Zoom</v-btn>
          <v-btn color="error" text large class="mr-2" @click="fullscreen = false" v-else>Sin, Zoom</v-btn>
          <!-- Guardar la información  -->
          <v-btn
            color="primary" 
            dark 
            class="elevation-6" 
            large 
            @click="dialgImagen = false"
          >Okey</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- VER PDF -->
    <v-dialog v-model="dialogPDF" max-width="650px" persistent>
      <v-card class="elevation-0">
        <!-- Imagen -->
        <embed :src="usuario.url_servidor + '/whatsapp-imagenes/' + pdf" width="650" height="700" type="application/pdf" v-if="usuario">
        <v-card-actions class="pt-0" >
          <v-spacer></v-spacer>
          <!-- Guardar la información  -->
          <v-btn
            color="error" 
            dark 
            class="elevation-6" 
            block
            @click="dialogPDF = false"
          >Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</div>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'


  export default {
  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores ],


    data: () => ({
      buscarPregunta:'',
      buscarActive:false,

    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      mensajes:[],
      usuarios:[],
      respuesta:'',
      puestos:[],

      usuario: null,
      buscarUsuario:'',
      buscarUsuarioActive:false,
      pregunta: null,
      frecuenteActive:false,
      dialgImagen:false,
      preguntaOficial:'',
      respuestaOficial:'',
      alumno:null,
      imagen: null,
      url: 'https://escuelakpi.club/kpi/whatsapp-imagenes/',
      fullscreen: false,
      pdf:null,
      dialogPDF: false,
      chats:[],
      searchMensaje:'',

      folioProspecto:'',
      url_servidor:''
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),

      filterChats(){
      	if( this.usuario ){
	        if( this.buscarUsuario != '' ){
	          return this.chats.filter((p)=>{
              var nom       = p.froms.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
              const buscar  = this.buscarUsuario.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"")

              var nom2      = p.notifyName ? p.notifyName.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"") : '';

	            return nom.match(buscar) || nom2.match(buscar);
	          })
	        }else{ return this.chats }
      	}
      },

      filterMensajes(){
        if( this.searchMensaje != '' ){
          return this.mensajes.filter((p)=>{

            if( p.type == 'chat' ){
              var mensajes  = p.body.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
              const buscar  = this.searchMensaje.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"")
              return mensajes.match(buscar);
            }else{
              return false
            }

          })
        }else{ return this.mensajes }
      },

      tamanioPantalla () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            return (this.$vuetify.breakpoint.height / 2)
          break;
          case 'sm': 
            return this.$vuetify.breakpoint.height - 100
          break;
          case 'md':
            return this.$vuetify.breakpoint.height - 100
          break;
          case 'lg':
            return this.$vuetify.breakpoint.height - 100
          break;
          case 'xl':
            return this.$vuetify.breakpoint.height - 100
          break;
        }
      },
    },

    watch:{
      usuario( val ){
        if( val ){ this.getChats( val )}
      }
    },

    async created () {
      await this.initialize()
    },

    methods: {
      initialize () {
      	this.cargar = true
        this.usuarios = []
        this.mensajes = []
        return this.$http.get('whatsapp.usuarios').then(response=>{
        	this.usuarios = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getChats ( value ) {
        this.cargar = true
        this.chats = []
        return this.$http.post('whatsapp.chats', value ).then(response=>{
          this.chats = response.data
          this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      abrirMensajes ( chat ) {
        this.alumno   = chat
        this.cargar   = true
        this.mensajes = []

        const payload = {
          tos:              chat.tos,
          froms:            chat.froms,
          whatsappservidor: this.usuario.whatsappservidor
        }

        return this.$http.post('whatsapp.mensajes', payload ).then(response=>{
          this.mensajes = response.data
          this.cargar      = false
    		  setTimeout(function(){document.getElementById('mensajesChat').scrollTop=document.getElementById('mensajesChat').scrollHeight},1000);
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },

      verImagen( value ){
        this.imagen      = value
        this.dialgImagen = true
      },

      verPDF( value ){
        this.pdf         = value
        this.dialogPDF   = true
      },

      avatar ( nombre ) {
        return "https://ui-avatars.com/api/?background=1976d2&color=fff&rounded=true&format=svg&name=" + nombre
      },

      avatarSeleccionado ( nombre ) {
        return "https://ui-avatars.com/api/?background=ffffff&color=e91e63&rounded=true&format=svg&name=" + nombre
      },

      buscarProspecto(){
      	const payload = {
      		prospecto: this.folioProspecto
      	}

        this.cargar   = true
        this.mensajes = []

        return this.$http.post('whatsapp.buscar.telefono', payload ).then(response=>{
          this.cargar   = false
          this.mensajes = response.data
    		  setTimeout(function(){document.getElementById('mensajesChat').scrollTop=document.getElementById('mensajesChat').scrollHeight},1000);
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })


      }
    },
  }
</script>

<style  scoped>

  #preguntas::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > #preguntas::-webkit-scrollbar:vertical {
    width: 4px !important;
  }

  #preguntas::-webkit-scrollbar:vertical{
    width: 4px !important;
  }

  #preguntas::-webkit-scrollbar-button:increment,#preguntas::-webkit-scrollbar-button{
    display: none;
  }

  #preguntas::-webkit-scrollbar:horizontal{
    display: none;
  }

  #preguntas::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }

  /**********************************/

  #mensajesChat::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > #mensajesChat::-webkit-scrollbar:vertical {
    width: 4px !important;
  }

  #mensajesChat::-webkit-scrollbar:vertical{
    width: 4px !important;
  }

  #mensajesChat::-webkit-scrollbar-button:increment,#mensajesChat::-webkit-scrollbar-button{
    display: none;
  }

  #mensajesChat::-webkit-scrollbar:horizontal{
    display: none;
  }

  #mensajesChat::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }

  .container {
    padding:0;
    background-color: #FFF; 
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    height: 750px;
    margin-top: 25px;
  }

  /* ===== MENU ===== */
  .menu {
    float: left;
    height: 700px;;
    width: 70px;
    background: #4768b5;
    background: -webkit-linear-gradient(#4768b5, #35488e);
    background: -o-linear-gradient(#4768b5, #35488e);
    background: -moz-linear-gradient(#4768b5, #35488e);
    background: linear-gradient(#4768b5, #35488e);
    box-shadow: 0 10px 20px rgba(0,0,0,0.19);
  }

  .menu .items {
    list-style:none;
    margin: auto;
    padding: 0;
  }

  .menu .items .item {
    height: 70px;
    border-bottom: 1px solid #6780cc;
    display:flex;
    justify-content: center;
    align-items: center;
    color: #9fb5ef;
    font-size: 17pt;
  }

  .menu .items .item-active {
    background-color:#5172c3;
    color: #FFF;
  }

  .menu .items .item:hover {
    cursor: pointer;
    background-color: #4f6ebd;
    color: #cfe5ff;
  }

  /* === CONVERSATIONS === */

  .discussions {
    width: 35%;
    height: 702px;
    box-shadow: 0px 8px 10px rgba(0,0,0,0.20);
    overflow: hidden;
    display: inline-block;
  }

  .discussions .discussion {
    width: 100%;
    height: 90px;
    background-color: #FAFAFA;
    display:flex;
    align-items: center;
    cursor: pointer;
  }

  .discussions .search {
    display:flex;
    align-items: center;
    justify-content: center;
    color: #E0E0E0;
  }

  .discussions .search .searchbar {
    height: 40px;
    background-color: #FFF;
    width: 70%;
    padding: 0 20px;
    border-radius: 50px;
    border: 1px solid #EEEEEE;
    display:flex;
    align-items: center;
    cursor: pointer;
  }

  .discussions .search .searchbar input {
    margin-left: 15px;
    height:38px;
    width:100%;
    border:none;
    font-family: 'Montserrat', sans-serif;;
  }

  .discussions .search .searchbar *::-webkit-input-placeholder {
      color: #E0E0E0;
  }
  .discussions .search .searchbar input *:-moz-placeholder {
      color: #E0E0E0;
  }
  .discussions .search .searchbar input *::-moz-placeholder {
      color: #E0E0E0;
  }
  .discussions .search .searchbar input *:-ms-input-placeholder {
      color: #E0E0E0;
  }

  .discussions .message-active {
    height: 90px;
    background-color: #FFF;
    border-right: solid 5px #E91E63;
  }

  .discussions .discussion .photo {
    margin-left:20px;
    display: block;
    width: 45px;
    height: 45px;
    background: #E6E7ED;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .online {
    position: relative;
    top: 30px;
    left: 35px;
    width: 13px;
    height: 13px;
    background-color: #8BC34A;
    border-radius: 13px;
    border: 3px solid #FAFAFA;
  }

  .desc-contact {
    height: 43px;
    width:50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .discussions .discussion .name {
    margin: 0 0 0 20px;
    font-family:'Montserrat', sans-serif;
    font-size: 11pt;
    color:#515151;
  }

  .discussions .discussion .message {
    margin: 6px 0 0 20px;
    font-family:'Montserrat', sans-serif;
    font-size: 9pt;
    color:#515151;
  }

  .timer {
    margin-left: 15%;
    font-family:'Open Sans', sans-serif;
    font-size: 11px;
    padding: 3px 8px;
    color: #BBB;
    background-color: #FFF;
    border: 1px solid #E5E5E5;
    border-radius: 15px;
  }

  .chat {
    width: calc(100%);
  }

  .header-chat {
    background-color: #FFF;
    height: 90px;
    box-shadow: 0px 3px 2px rgba(0,0,0,0.100);
    display:flex;
    align-items: center;
  }

  .chat .header-chat .icon {
    margin-left: 30px;
    color:#515151;
    font-size: 14pt;
  }

  .chat .header-chat .name {
    margin: 0 0 0 20px;
    text-transform: uppercase;
    font-family:'Montserrat', sans-serif;
    font-size: 13pt;
    color:#515151;
  }

  .chat .header-chat .right {
    position: absolute;
    right: 40px;
  }

  .chat .messages-chat {
    padding: 25px 10px;
  }

  .chat .messages-chat .message {
    display:flex;
    align-items: center;
    margin-bottom: 2px;
  }

  .chat .messages-chat .message .photo {
    display: block;
    width: 45px;
    height: 45px;
    background: #E6E7ED;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .chat .messages-chat .text {
    margin: 0 35px;
    background-color: #f6f6f6;
    padding: 15px;
    border-radius: 12px;
  }

  .text-only {
    white-space: pre-line;
  }

  .time {
    font-size: 12px;
    color:lightgrey;
    margin-bottom:10px;
    margin-left: 38px;
  }

  .response-time {
    float: right;
    margin-right: 40px !important;
  }

  .response {
    float: right;
    margin-right: 0px !important;
    margin-left: auto; /* flexbox alignment rule */
    max-width: 90%;
  }

  .noresponse {
    max-width: 90%;
  }

  .response .text {
    background-color: #E91E63 !important;
    color: white;
  }

  .footer-chat {
    width: calc(65% - 66px);
    height: 80px;
    display:flex;
    align-items: center;
    position:absolute;
    bottom: 0;
    background-color: transparent;
    border-top: 2px solid #EEE;
    
  }

  .chat .footer-chat .icon {
    margin-left: 30px;
    color:#C0C0C0;
    font-size: 14pt;
  }

  .chat .footer-chat .send {
    color:#fff;
    background-color: #4f6ebd;
    position: absolute;
    right: 50px;
    padding: 12px 12px 12px 12px;
    border-radius: 50px;
    font-size: 14pt;
  }

  .chat .footer-chat .name {
    margin: 0 0 0 20px;
    text-transform: uppercase;
    font-family:'Montserrat', sans-serif;
    font-size: 13pt;
    color:#515151;
  }

  .chat .footer-chat .right {
    position: absolute;
    right: 40px;
  }

  .write-message {
    border:none !important;
    width:60%;
    height: 50px;
    margin-left: 20px;
    padding: 10px;
  }

  .footer-chat *::-webkit-input-placeholder {
    color: #C0C0C0;
    font-size: 13pt;
  }
  .footer-chat input *:-moz-placeholder {
    color: #C0C0C0;
    font-size: 13pt;
  }
  .footer-chat input *::-moz-placeholder {
    color: #C0C0C0;
    font-size: 13pt;
    margin-left:5px;
  }
  .footer-chat input *:-ms-input-placeholder {
    color: #C0C0C0;
    font-size: 13pt;
  }

  .clickable {
    cursor: pointer;
  }

  .text-only .text{
    white-space: pre-line;
  }

  .subnombrechat{
    color: #07C9F9;
    font-size: 8pt;
  }
</style>
